import { getCustomers } from "actions/customers/customers.actions";
import { getMerchants } from "actions/merchants/merchants.actions";
import { getCommissions } from "actions/commissions/commissions.actions";
import {
  getCities,
  getCountries,
  getStates,
  setCities,
  setStates,
} from "actions/regions/regions.actions";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import Pagination from "../components/pagination";
import CommissionsTableCard from "./commissions-table-card";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import { useSearchParams } from "react-router-dom";
import Widget from "components/widget/Widget";
import { SiBuzzfeed } from "react-icons/si";
import { TbAffiliateFilled } from "react-icons/tb";
import { getCurrencies } from "actions/currency/currency.action";

const Commissions = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    commissionsCount,
    total_commission_amount,
    total_order_amount,
    commissions,
    loading,
  } = useSelector((state) => state.commission);
  const { merchants, loading: merchantLoading } = useSelector(
    (state) => state.merchant
  );
  const { customers, loading: customerLoading } = useSelector(
    (state) => state.customer
  );
  const {
    countries,
    states,
    cities,
    loading: regionLoading,
  } = useSelector((state) => state.region);
  const { currencies } = useSelector((state) => state.currencies);
  const { user } = useSelector((state) => state.auth);
  const userCurrency = currencies.find((c) => c.code === user.currency);
  const order_number = searchParams.get("order_number");
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [orderNo, setOrderNo] = useState(order_number ?? "");
  const [amountGreaterThan, setAmountGreaterThan] = useState(null);
  const [amountLesserThan, setAmountLesserThan] = useState(null);
  const [rate, setRate] = useState(null);
  const [rateGreaterThan, setRateGreaterThan] = useState(null);
  const [rateLesserThan, setRateLesserThan] = useState(null);
  const [fromDate, setFromDAte] = useState(
    dayjs("1980-01-01").format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
  const optionAll = { name: "All", value: "" };

  useEffect(() => {
    dispatch(getMerchants(page, 1000));
    dispatch(getCustomers(page, 1000));
    dispatch(getCountries());
    dispatch(getCurrencies(1, 1000));
  }, []);

  useEffect(() => {
    setState("");
    setCity("");
    dispatch(setStates({ states: [], meta: { totalDocuments: 0 } }));
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    if (!country) return;
    const temp_country = countries.find((c) => c.id === country);
    dispatch(getStates(temp_country.code));
  }, [country]);

  useEffect(() => {
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    setCity("");
    if (!country || !state) return;
    const temp_country = countries.find((c) => c.id === country);
    const temp_state = states.find((s) => s.id === state);
    dispatch(getCities(temp_country.code, temp_state.code));
  }, [state]);

  const getFilteredOrders = () => {
    setFiltersApplied(true);
    dispatch(
      getCommissions(
        page,
        limit,
        merchantId,
        customerId,
        country,
        state,
        city,
        fromDate,
        toDate,
        orderNo,
        amountGreaterThan,
        amountLesserThan,
        rate,
        rateGreaterThan,
        rateLesserThan
      )
    );
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied;
    getFilteredOrders();
    setFiltersApplied(prev_filter_state);
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false);
    setPage(1);
    setMerchantId("");
    setCustomerId("");
    setCountry("");
    setState("");
    setCity("");
    setFromDAte(dayjs("1980-01-01").format("YYYY-MM-DD"));
    setToDate(dayjs(new Date()).format("YYYY-MM-DD"));
    setOrderNo("");
    setAmountGreaterThan(null);
    setAmountLesserThan(null);
    setRate(null);
    setRateGreaterThan(null);
    setRateLesserThan(null);
    dispatch(getCommissions(1, limit));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getFilteredOrders();
    }
  };

  return (
    <div className="h-full w-full">
      {/* widget */}
      <div className="grid grid-cols-12 gap-4 py-4 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
        <div className="col-span-12 sm:col-span-6  ">
          <Widget
            icon={<TbAffiliateFilled className="h-7 w-7" />}
            title={"Total Orders amount"}
            // subtitle={"SAR" + " " + total_order_amount}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` + " " + total_order_amount ?? 0
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6  ">
          <Widget
            icon={<SiBuzzfeed className="h-7 w-7" />}
            title={"Total Commission amount"}
            // subtitle={"SAR" + " " + total_commission_amount}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` +
                " " +
                total_commission_amount ?? 0
            }
          />
        </div>
      </div>
      <CommissionsTableCard commissions={commissions}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2">
            {/* <h5 className="text-lg font-semibold text-gray-700 dark:text-white">
              Total&nbsp;Amount: {total_commission_amount}
            </h5> */}
          </div>

          {/* filter buttons */}
          <div className="flex items-center justify-end gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={removeAllFilters}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
                type="button"
              >
                <MdFilterAltOff className="h-4 w-4" />
                {"Clear Filters"}
              </button>
            </div>
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={getFilteredOrders}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
                type="button"
              >
                <CiFilter className="h-4 w-4" />
                {"Apply Filters"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Merchant, Customer and Date */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <HeadlessSelectObject
                disable={!merchants.length || merchantLoading}
                title={merchantLoading ? "Loading..." : "All Merchants"}
                options={[
                  optionAll,
                  ...merchants.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={merchantId}
                onSelect={setMerchantId}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!customers.length || customerLoading}
                title={customerLoading ? "Loading..." : "All Customers"}
                options={[
                  optionAll,
                  ...customers.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={customerId}
                onSelect={setCustomerId}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 p-1 md:w-1/3">
              <input
                className="text-sm"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDAte(e.target.value)}
              />
              <p className="text-lg font-bold">-</p>
              <input
                className="text-sm"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <HeadlessSelectObject
                disable={!countries.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Countries"}
                options={[
                  optionAll,
                  ...countries.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={country}
                onSelect={setCountry}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!states.length || regionLoading}
                title={regionLoading ? "Loading..." : "All States"}
                options={[
                  optionAll,
                  ...states.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={state}
                onSelect={setState}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!cities.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Cities"}
                options={[
                  optionAll,
                  ...cities.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={city}
                onSelect={setCity}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <input
                type="text"
                name="orderNo"
                placeholder="Order No"
                value={orderNo}
                onChange={(e) => setOrderNo(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <input
                type="number"
                name="amountGreaterThan"
                placeholder="Amount Greater than"
                value={amountGreaterThan}
                onChange={(e) => setAmountGreaterThan(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <input
                type="number"
                name="amountLesserThan"
                placeholder="Amount Lesser than"
                value={amountLesserThan}
                onChange={(e) => setAmountLesserThan(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <input
                type="number"
                name="rate"
                placeholder="Rate"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <input
                type="number"
                name="rateGreaterThan"
                placeholder="Rate Greater than"
                value={rateGreaterThan}
                onChange={(e) => setRateGreaterThan(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <input
                type="number"
                name="rateLesserThan"
                placeholder="Rate Lesser than"
                value={rateLesserThan}
                onChange={(e) => setRateLesserThan(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
          </div> */}
        </div>
      </CommissionsTableCard>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={commissionsCount}
      />
    </div>
  );
};

export default Commissions;
