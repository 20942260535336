import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const GetAllRequests = (page = 1, limit = 50) => {
  return RestClient.Post(
    `${API_URL.GET_CUSTOMER_REQUESTS}?page=${page}&limit=${limit}`
  );
};

const exportedObject = {
  GetAllRequests,
};

export default exportedObject;
