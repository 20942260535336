import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import { GetAllRequests } from "actions/customerRequest/customerRequestActions";
import CustomerRequestsTableCard from "./invoices-table-card";

const CustomerRequests = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { requestCount, requests } = useSelector(
    (state) => state.customerRequest
  );

  useEffect(() => {
    dispatch(GetAllRequests(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <CustomerRequestsTableCard requests={requests} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={requestCount}
      />
    </div>
  );
};

export default CustomerRequests;
