import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import convertToFormData from "utils/ConvertToFormData";
import CardHeader from "../components/cardHeader";
import HeadlessSelect from "../components/headless/select.headless";
import PhoneNumberInput from "../components/phoneNumberInput";
import { sendManualTopup } from "actions/topUps/topUps.actions";
import { MANUAL_TOPUP_TYPE } from "constants/app.constants";
import { getMerchants } from "actions/merchants/merchants.actions";
import HeadlessSelectObject from "../components/headless/selectObject.headless";

const initialTopupData = {
  phone_number: {
    code: "",
    number: "",
  },
  points: 10,
  comment: "",
  type: "",
  merchant_id: "",
};

const ManulaTopupForm = () => {
  const optionAll = { name: "All", value: "" };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, merchants } = useSelector((state) => state.merchant);
  const [topupData, setTopupData] = useState({
    ...initialTopupData,
  });

  useEffect(() => {
    dispatch(getMerchants(1, 1000));
  }, []);
  
  const onChangeFormData = ({ name, value }) => {
    if (name === "points") {
      // Use regex to match only integers between 0 and 100000
      const regex = /^(100000|[1-9][0-9]{0,4}|0)$/; // Matches 0 to 100000
      const parsedValue = parseInt(value, 10);

      // Check if the value matches the regex and is within the limit
      if (regex.test(value) && parsedValue >= 0 && parsedValue <= 100000) {
        setTopupData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        // Optionally handle invalid input (e.g., set an error state)
        console.error(
          "Invalid input for points. Must be an integer between 0 and 100000."
        );
      }
    } else {
      setTopupData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = () => {
    let payload = { ...topupData };
    Object.keys(payload).forEach((key) => {
      if (initialTopupData[key] == undefined) {
        delete payload[key];
      }
    });
    if (topupData.type === MANUAL_TOPUP_TYPE.NETWORK) {
      delete payload.merchant_id;
    }
    console.log({ payload });

    dispatch(sendManualTopup(payload, navigate));
  };

  return (
    <div className="h-full w-full bg-white">
      <CardHeader
        label={`Manual Topup`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate(-1),
            variant: "dark",
          },
          {
            text: "Send",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          {/* phone number  */}
          <div className="md:w-1/2">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Phone Number
            </label>
            <PhoneNumberInput
              phone_number={topupData.phone_number}
              onPhoneNumberChange={(number) =>
                onChangeFormData({ name: "phone_number", value: number })
              }
            />
          </div>

          {/* Ponits */}
          <div className="w-full md:w-1/2">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Points
            </label>
            <input
              type="number"
              name="points"
              value={topupData.points}
              onChange={(e) =>
                onChangeFormData({
                  name: "points",
                  value: e.target.value,
                })
              }
              className="block w-full rounded-md border border-gray-300 px-2 py-1.5 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          {/* Type  */}
          <div className="w-full md:w-1/2 ">
            <HeadlessSelect
              label="Type"
              title={"select type"}
              options={Object.values(MANUAL_TOPUP_TYPE)}
              selected={topupData.type}
              onSelect={(value) => onChangeFormData({ name: "type", value })}
            />
          </div>

          {/* Merchants */}
          {topupData.type === MANUAL_TOPUP_TYPE.MERCHANT ? (
            <div className="md:w-1/2">
              <HeadlessSelectObject
                label="Merchant"
                disable={!merchants.length || loading}
                title={loading ? "Loading..." : "All Merchants"}
                options={[
                  optionAll,
                  ...merchants.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={topupData.merchant_id}
                onSelect={(value) =>
                  onChangeFormData({ name: "merchant_id", value })
                }
              />
            </div>
          ) : null}
        </div>

        {/* comment */}
        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Comment
          </label>
          <textarea
            required
            name="message_body"
            value={topupData.comment}
            onChange={(e) =>
              onChangeFormData({
                name: "comment",
                value: e.target.value,
              })
            }
            className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            rows="2"
          />
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Sending..." : "Send"}
          </button>
          {/* <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ManulaTopupForm;
