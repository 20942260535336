import { setAlert } from "actions/alert/alert.actions";
import { deleteMerchant } from "actions/merchants/merchants.actions";
import Card from "components/card";
import { PERMISSION_ACTION_TYPES, PERSMISSIONS_TYPES } from "constants/role.constants";
import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { hasPermission } from "utils/permissions";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import ToggleSwitch from "../components/toggleSwitch";
import { publishMerchant } from "actions/merchants/merchants.actions";
import SortTable from "../components/sortTable";
import ImageModal from "../components/imageModal";

const getStatusClasses = (status) => {
  switch (status) {
    case "True":
      return "bg-green-200 text-green-800";
    case "False":
      return "bg-red-200 text-red-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

function MerchantsTableCard({ merchants, children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [deleteMerchantId, setDeleteMerchantId] = useState(null);
  const [qr_code, setQRUrl] = useState(null);

  const handleCancelDelete = () => {
    setShowDelModal(false);
    setDeleteMerchantId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchant(deleteMerchantId));
    handleCancelDelete();
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(setAlert("link copied", AlertTypes.SUCCESS));
  };
  const handlePublish = (merchant_id) => {
    dispatch(publishMerchant(merchant_id));
  };

  const tableHeaders = [
    {
      value: (
        <div className="flex w-full items-center gap-2 ">
          <div className=" h-10 w-10 " />
          <p className="w-auto">Name</p>
        </div>
      ),
      sort: false,
    },
    { value: "RQ-Code", sort: false },
    { value: "Code", sort: true },
    { value: "Email", sort: true },
    { value: "Phone", sort: false },
    { value: "Country", sort: true },
    { value: "State", sort: true },
    { value: "City", sort: true },
    { value: "Currency", sort: false },
    { value: "Foodics", sort: false },
    { value: "Created By", sort: true },
    { value: "Onboarded", sort: true },
    { value: "Publish", sort: false },
    { value: "Actions", sort: false },
  ];
  const records = merchants.map((merchant) => ({
    logo: {
      value: (
        <div className="flex w-full items-center gap-2">
          <div className=" h-10 w-10 ">
            <img
              className="h-9 w-9 rounded-full"
              src={
                merchant.logo !== null && merchant.logo !== undefined
                  ? merchant.logo
                  : ""
              }
              alt=""
            />
          </div>
          <p className="w-auto"> {merchant.name.split(" ").join("\u00A0")}</p>
        </div>
      ),
      sortKey: merchant.name,
    },
    qr_code: {
      value: (
        <div className="flex cursor-pointer items-center justify-center">
          <img
            className="h-10 w-10 rounded-full"
            src={merchant.qr_code}
            alt=""
            onClick={() => {
              setQRUrl(merchant.qr_code);
            }}
          />
        </div>
      ),
      sortKey: null,
    },
    code: { value: merchant.unique_code, sortKey: merchant.unique_code },
    email: {
      value: merchant.contact_info?.email,
      sortKey: merchant.contact_info?.email,
    },
    phone: {
      value: merchant.contact_info?.phone,
      sortKey: merchant.contact_info?.phone,
    },
    country: {
      value: merchant.country_id?.name,
      sortKey: merchant.country_id?.name,
    },
    state: { value: merchant.state_id?.name, sortKey: merchant.state_id?.name },
    city: { value: merchant.city_id?.name, sortKey: merchant.city_id?.name },
    currency: { value: merchant.currency, sortKey: null },
    is_connected_foodics: {
      value: merchant.is_connected_foodics ? (
        <div className="flex items-center justify-center">
          <span className="inline-block text-nowrap rounded-full bg-green-500 px-2 py-1 text-sm font-bold text-white">
            Connected
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <button
            onClick={() => handleCopy(merchant.foodics_integration_link)}
            className={
              "flex items-center gap-2 text-nowrap rounded-full bg-blue-500 px-2 py-1 text-sm font-bold text-white hover:bg-blue-600"
            }
          >
            Connection <IoCopyOutline className="h-4 w-4 hover:font-semibold" />
          </button>
        </div>
      ),
      sortKey: null,
    },
    created_by: {
      value: merchant?.created_by?.name ?? "",
      sortKey: merchant?.created_by?.name,
    },
    is_onboarded: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              merchant?.is_onboarded ? "True" : "False"
            )}`}
          >
            {merchant?.is_onboarded
              ? "Onboarded"
              : "Not Onboarded".split(" ").join("\u00A0")}
          </span>
        </div>
      ),
      sortKey: merchant?.is_onboarded,
    },
    publish: {
      value: (
        <ToggleSwitch
          isEnabled={merchant?.is_published ?? false}
          handleChange={() => {
            handlePublish(merchant.id);
          }}
        />
      ),
      sortKey: null,
    },
    actions: {
      value: (
        <div className="flex items-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) ? (
            <MdOutlineEdit
              onClick={() => navigate(`/merchants/update/${merchant.id}`)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
            />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      {qr_code ? (
        <ImageModal onBack={() => setQRUrl(null)}>
          <div className="flex h-96 w-96 items-center justify-center">
            <img
              className="size-80 cursor-pointer "
              src={qr_code ?? ""}
              alt=""
            />
          </div>
        </ImageModal>
      ) : null}
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText="OK!"
          confirmBtnType=""
        >
          <div className="flex flex-col gap-4"></div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Merchants"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/merchants/create"),
                    variant: "primary",
                  },
                ]
              : null
          }
        />
        {children}

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MerchantsTableCard;
