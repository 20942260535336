import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  commissionsCount: 0,
  total_order_amount: 0,
  total_commission_amount: 0,
  commissions: [],
  loading: false,
};

export const commissionsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_COMMISSIONS:
      return {
        ...state,
        commissionsCount: payload?.meta?.total_documents || 0,
        total_order_amount: payload.total_order_amount || 0,
        total_commission_amount: payload.total_commission_amount || 0,
        commissions: payload.commissions,
        loading: false,
      };
    case ActionTypes.SET_COMMISSION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_COMMISSION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
