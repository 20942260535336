import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import CustomerRequests from "../../services/customerRequests/CustomerRequests";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REQUEST_LOADING,
    payload: loading,
  });
};

export const setRequests = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REQUESTS,
    payload: data,
  });
};

export const resetRequestState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_REQUEST_STATE,
  });
};

export const GetAllRequests = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CustomerRequests.GetAllRequests(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setRequests(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Requests", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
