import Card from "components/card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatLocalTimeDate } from "utils/App.utils";
import CardHeader from "../components/cardHeader";
import SortTable from "../components/sortTable";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import merchantPointsIcon from "assets/img/airplay.png";
import { getCurrencies } from "actions/currency/currency.action";
import { formatNumberWithCommas } from "utils/App.utils";

const CustomerRequestsTableCard = ({ requests }) => {
  const dispatch = useDispatch();
  const { currencies } = useSelector((state) => state.currencies);

  useEffect(() => {
    dispatch(getCurrencies(1, 1000));
  }, []);

  const tableHeaders = [
    {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center" />
          <div className="flex items-center gap-1">
            <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
            <p>Merchant</p>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center" />
          <div className="flex items-center gap-1">
            <p>Customer</p>
          </div>
        </div>
      ),
      sort: true,
    },
    { value: "Redeem Amount", sort: true },
    { value: "Request Date", sort: true },
  ];

  const records = requests.map((req) => ({
    merchant: {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              className="h-9 w-9 rounded-full"
              src={req.merchant_id?.logo ?? ProfileIcon}
              alt=""
            />
          </div>
          {req.merchant_id?.name ?? ""}
        </div>
      ),
      sortKey: req.merchant_id?.name ?? "N/A",
    },
    customer: {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              className="h-9 w-9 rounded-full"
              src={req.customer_id?.profile_pic ?? ProfileIcon}
              alt=""
            />
          </div>
          {req.customer_id?.name ?? "N/A"}
        </div>
      ),
      sortKey: req.customer_id?.name ?? "",
    },
    amount: {
      value: `${req.merchant_id?.currency || ""} ${formatNumberWithCommas(
        Number(req?.amount || 0).toFixed(2)
      )}`,
      sortKey: req.amount,
    },
    date: {
      value: req.created_at ? formatLocalTimeDate(req.created_at) : "",
      sortKey: req.created_at,
    },
  }));

  return (
    <Card extra={" bg-white"}>
      <CardHeader label={"Customer Requests"} />
      <SortTable
        tableData={{
          headers: tableHeaders,
          records,
        }}
      />
    </Card>
  );
};

export default CustomerRequestsTableCard;
