import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import CommissionService from "../../services/commissions/CommissionService";
import * as ActionTypes from "../actionTypes";
import { downloadFile } from "utils/App.utils";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_COMMISSION_LOADING,
    payload: loading,
  });
};

export const setCommissions = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_COMMISSIONS,
    payload: data,
  });
};

export const resetCommissionState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_COMMISSION_STATE,
  });
};

export const getCommissions =
  (
    page,
    limit,
    merchant_id,
    customer_id,
    country_id,
    state_id,
    city_id,
    startDate,
    endDate,
    order_number,
    amountGreaterThan,
    amountLesserThan,
    rate,
    rateGreaterThan,
    rateLesserThan
  ) =>
  async (dispatch) => {
    // console.log(endDate);
    try {
      dispatch(setLoading(true));
      const response = await CommissionService.getCommissions(
        page,
        limit,
        merchant_id,
        customer_id,
        country_id,
        state_id,
        city_id,
        startDate,
        endDate,
        order_number,
        amountGreaterThan,
        amountLesserThan,
        rate,
        rateGreaterThan,
        rateLesserThan
      );
      if (response.status == 1 && response.data) {
        console.log({ SET_ORDERSSSS: response.data });
        dispatch(setCommissions(response.data));
        return;
      }
      dispatch(
        setAlert(response.message ?? "failed to load orders", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
