import Card from "components/card";

const Widget = ({ icon, title, subtitle, extra }) => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] cursor-pointer transition-all duration-300 hover:scale-105 hover:shadow-xl p-2">
      <div className=" flex min-h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="min-h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
        <>{extra}</>
      </div>
    </Card>
  );
};

export default Widget;
