import { getCurrencies } from "actions/currency/currency.action";
import { getDashboardStats } from "actions/dashboard/dashboard.action";
import Widget from "components/widget/Widget";
import { Fragment, useEffect } from "react";
import { AiFillShop } from "react-icons/ai";
import { BsFillPhoneFill, BsPersonWorkspace } from "react-icons/bs";
import {
  FaArrowDown,
  FaArrowUp,
  FaDollarSign,
  FaImages,
  FaShoppingBasket,
  FaTablet,
} from "react-icons/fa";
import { GiProfit } from "react-icons/gi";
import { IoPerson } from "react-icons/io5";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { SiBuzzfeed } from "react-icons/si";
import {
  TbAffiliateFilled,
  TbArrowsUpDown,
  TbCurrencySolana,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/App.utils";

const WidgetCards = () => {
  const dispatch = useDispatch();
  const { stats, loading } = useSelector((state) => state.dashboard);
  const { currencies } = useSelector((state) => state.currencies);
  const { user } = useSelector((state) => state.auth);
  const userCurrency = currencies.find((c) => c.code === user.currency);
  const {
    active_users_event,
    new_order_event,
    new_transaction_event,
    new_customer_event,
    new_merchant_event,
    new_membership_event,
  } = useSelector((state) => state.events);

  useEffect(() => {
    if (loading) return;
    dispatch(getDashboardStats());
  }, [
    new_order_event.id,
    new_transaction_event.id,
    new_customer_event.id,
    new_merchant_event.id,
    new_membership_event.id,
  ]);

  useEffect(() => {
    dispatch(getCurrencies(1, 1000));
  }, []);

  const offlineMerchants = stats?.totalMerchants
    ? Math.max(
        stats.totalMerchants -
          active_users_event.data?.merchant_console_users?.length,
        0
      )
    : 0;
  const offlineVendors = stats?.totalVendorDevices
    ? Math.max(
        stats.totalVendorDevices -
          active_users_event.data?.vendor_app_users?.length,
        0
      )
    : 0;

  return (
    <Fragment>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-12 gap-4 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<IoPerson className="h-7 w-7" />}
            title={"Total Customers"}
            subtitle={stats?.totalCustomers || 0}
            extra={
              <div className="flex w-full justify-between gap-5">
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Verified
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700  dark:text-white">
                    <div className={`rounded-full `}>
                      <MdCheckCircle className="text-green-500" />
                    </div>
                    {stats?.verifiedCustomers}
                  </h4>
                </div>
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Not Verified
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                    <div className={`rounded-full `}>
                      <MdCancel className="text-red-500" />
                    </div>
                    {stats?.nonVerifiedCustomers}
                  </h4>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<BsFillPhoneFill className="h-7 w-7" />}
            title={"Active Customers"}
            subtitle={active_users_event.data?.mobile_app_users?.length || 0}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<BsPersonWorkspace className="h-7 w-7" />}
            title={"Active Admins"}
            subtitle={active_users_event.data?.admin_console_users?.length || 0}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<AiFillShop className="h-7 w-7" />}
            title={"Total Merchants"}
            subtitle={stats?.totalMerchants || 0}
            extra={
              <div className="flex w-full justify-between gap-5">
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Online
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                    <div className={`size-2 rounded-full bg-green-500`} />
                    {active_users_event.data?.merchant_console_users?.length ||
                      0}
                  </h4>
                </div>
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Offline
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                    <div className={`size-2 rounded-full bg-red-500`} />
                    {offlineMerchants}
                  </h4>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<FaTablet className="h-7 w-7" />}
            title={"Total Vendor Devices"}
            subtitle={stats?.totalVendorDevices || 0}
            extra={
              <div className="flex w-full justify-between gap-5">
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Online
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                    <div className={`size-2 rounded-full bg-green-500`} />
                    {active_users_event.data?.vendor_app_users?.length || 0}
                  </h4>
                </div>
                <div className=" flex w-auto flex-col justify-center">
                  <p className="font-dm text-xs font-medium text-gray-600">
                    Offline
                  </p>
                  <h4 className="flex items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                    <div className={`size-2 rounded-full bg-red-500`} />
                    {offlineVendors}
                  </h4>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<FaImages className="h-7 w-7" />}
            title={"Total NFTs Mint"}
            subtitle={stats?.totalNftMints || 0}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<TbCurrencySolana className="h-7 w-7" />}
            title={"Total Minting Fee"}
            subtitle={`${stats?.totalMintFees || 0} Solana`}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<TbArrowsUpDown className="h-7 w-7" />}
            title={"Total Transactions"}
            subtitle={formatNumber(stats?.totalTransactions || 0, 2)}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<FaShoppingBasket className="h-7 w-7" />}
            title={"Total Orders"}
            subtitle={formatNumber(stats?.totalOrders || 0, 2)}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<GiProfit className="h-7 w-7" />}
            title={"Total Topups"}
            subtitle={formatNumber(stats?.totalTopups || 0, 2)}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<TbAffiliateFilled className="h-7 w-7" />}
            title={"Total Affiliate Orders"}
            subtitle={formatNumber(stats?.totalAffiliateOrders || 0, 2)}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 ">
          <Widget
            icon={<FaDollarSign className="h-7 w-7" />}
            title={"Total Customers Spending"}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` +
              " " +
              formatNumber(stats?.totalCustomersSpending || 0, 3)
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4   ">
          <Widget
            icon={<FaArrowDown className="h-7 w-7" />}
            title={"Total Earnings"}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` +
              " " +
              formatNumber(stats?.earnings?.total_points || 0, 2)
            }
            extra={
              <>
                {/* <h4 className="flex w-full items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                <div className={`size-2 rounded-full bg-navy-500`} />
                Earnings
              </h4> */}
                <div className="flex w-full justify-between gap-5">
                  <div className=" flex w-auto flex-col justify-center">
                    <p className="font-dm text-xs font-medium text-gray-600">
                      Network
                    </p>
                    <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                      {`${userCurrency?.symbol ?? "SAR"}` +
                        " " +
                        formatNumber(stats?.earnings?.earning_network || 0, 2)}
                    </h4>
                  </div>
                  <div className=" flex w-auto flex-col justify-center">
                    <p className="font-dm text-xs font-medium text-gray-600">
                      Merchant
                    </p>
                    <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                      {`${userCurrency?.symbol ?? "SAR"}` +
                        " " +
                        formatNumber(stats?.earnings?.earning_merchant || 0, 2)}
                    </h4>
                  </div>
                </div>
              </>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-4   ">
          <Widget
            icon={<SiBuzzfeed className="h-7 w-7" />}
            title={"Total Commissions"}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` +
              " " +
              formatNumber(stats?.totalCommissionAmountEarned || 0, 2)
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-4   ">
          <Widget
            icon={<FaArrowUp className="h-7 w-7" />}
            title={"Total Redemptions"}
            subtitle={
              `${userCurrency?.symbol ?? "SAR"}` +
              " " +
              formatNumber(stats?.redemptions?.total_points || 0, 2)
            }
            extra={
              <>
                {/* <h4 className="flex w-full items-center gap-[2px] text-xs font-bold text-navy-700 dark:text-white">
                <div className={`size-2 rounded-full bg-navy-500`} />
                Redeems
              </h4> */}
                <div className="flex w-full justify-between gap-5">
                  <div className=" flex w-auto flex-col justify-center">
                    <p className="font-dm text-xs font-medium text-gray-600">
                      Network
                    </p>
                    <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                      {`${userCurrency?.symbol ?? "SAR"}` +
                        " " +
                        formatNumber(
                          stats?.redemptions?.redemption_network || 0,
                          2
                        )}
                    </h4>
                  </div>
                  <div className=" flex w-auto flex-col justify-center">
                    <p className="font-dm text-xs font-medium text-gray-600">
                      Merchant
                    </p>
                    <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                      {`${userCurrency?.symbol ?? "SAR"}` +
                        " " +
                        formatNumber(
                          stats?.redemptions?.redemption_merchant || 0,
                          2
                        )}
                    </h4>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default WidgetCards;
