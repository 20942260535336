
const EarningRedemtion = ({ merchantData, onChangeFormData }) => {

  return (
    <div className="flex flex-col justify-between gap-2 md:flex-row">
      <div className="w-full">
        <label className="mb-1 block text-base font-medium text-gray-800">
          Cashback Percentage
        </label>
        <input
          onChange={({ target }) =>
            onChangeFormData({
              name: target.name,
              value: Number(target.value),
            })
          }
          required
          type="number"
          name="cashback_percent"
          value={merchantData.cashback_percent}
          className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
        />
      </div>
      <div className="w-full">
        <label className="mb-1 block text-base font-medium text-gray-800">
          Topup Reward Points
        </label>
        <input
          onChange={({ target }) =>
            onChangeFormData({
              name: target.name,
              value: Number(target.value),
            })
          }
          required
          type="number"
          name="reward_points"
          value={merchantData.reward_points}
          className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default EarningRedemtion;
