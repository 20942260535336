import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  requestCount: 0,
  requests: [],
  loading: false,
};

export const customerRequestReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_REQUESTS:
      return {
        ...state,
        requestCount: payload?.meta?.total_documents || 0,
        requests: payload.active_sessions,
        loading: false,
      };
    case ActionTypes.SET_REQUEST_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_REQUEST_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
